import React from "react";
import { PlaylistModalConfig } from "../components/Modal/PlaylistModal/PlaylistModal";
import { indexName, LIST_DEF, TABLE_DEF, versionNo } from "../services/DataDef";
import { LocalDb } from "../services/IndexedDb";

export function useList() {
  const [playlists, setPlaylists] = React.useState(null);
  const [modalProps, setModalProps] = React.useState({ open: false });
  const ask = PlaylistModalConfig(setModalProps);

  const refresh = React.useCallback(() => {
    LocalDb.init([TABLE_DEF, LIST_DEF], indexName, versionNo);
    LocalDb.select(LIST_DEF.name).then((d) => {
      setPlaylists(d);
    });
  }, []);

  const tally = (track) => {
    return playlists?.filter((L) => L.items?.some((i) => i.ID === track.ID))
      .length;
  };

  const getLists = async () => {
    return await LocalDb.select(LIST_DEF.name);
  };

  const getList = async (name) => {
    const list = await LocalDb.select(
      LIST_DEF.name,
      (row) => row.name === name
    );
    return list[0];
  };

  const updateList = async (name, items) => {
    return await LocalDb.update(LIST_DEF.name, (row) => row.name === name, {
      name,
      items,
    });
  };

  const createList = async (name) => {
    await LocalDb.insert(LIST_DEF.name, [{ name, items: [] }]);
    return refresh();
  };

  const deleteTracks = async (name, tracks) => {
    const agent = await getList(name);
    const items = agent.items.filter((f) =>
      tracks.every((track) => f.ID !== track.ID)
    );
    return updateList(name, items).then(async () => {
      await refresh();
      return getList(name);
    });
  };

  const deleteTrack = async (name, track) => {
    const agent = await getList(name);
    const items = agent.items.filter((f) => f.ID !== track.ID);
    return updateList(name, items).then(() => {
      return refresh();
    });
  };

  const addTracksToList = async (name, tracks) => {
    if (!name) return;
    const agent = await getList(name);
    const items = (agent.items || []).concat(tracks);
    return updateList(name, items).then(() => {
      return refresh();
    });
  };

  const addToList = async (tracks) => {
    ask(tracks).then((name) => addTracksToList(name, tracks));
  };

  const showModal = () => {
    ask([]).then(console.log);
  };

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  return {
    playlists,
    getLists,
    createList,
    getList,
    updateList,
    modalProps,
    addTracksToList,
    addToList,
    showModal,
    deleteTrack,
    deleteTracks,
    tally,
  };
}
