import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "./ModelPictureMenu.css";
import { WindowManagerService } from "../../../../services/WindowManager.js";
import {
  AddAPhoto,
  AddPhotoAlternate,
  Person,
  Search,
} from "@material-ui/icons";
import {
  setModelImage,
  generateModelImage,
} from "../../../../services/VideoData";

const ModelPictureMenu = ({ name, modelFk, refresh, click }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const generateImage = async () => {
    const photo = await generateModelImage(name);
    const src = photo?.data?.answer?.src;
    setImage(src);
  };

  const setImage = (image) => {
    !!image &&
      !!modelFk &&
      setModelImage(modelFk, image).then(() => {
        handleClose();
        refresh && refresh();
      });
  };

  const update = () => {
    const image = prompt(`Enter image for ${name}`, "");
    setImage(image);
  };

  const find = () =>
    window.open(WindowManagerService.googlePhotoLink(name)) && handleClose();
  return (
    <>
      <span
        className="ModelPictureMenu app-data-item-model"
        onClick={handleClick}
        aria-label="delete"
      >
        {name}
      </span>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={find}>
          <Search /> Find Pictures of {name}...
        </MenuItem>
        {!!click && (
          <MenuItem onClick={() => click && click({ name, modelFk })}>
            <Person /> Model Info...
          </MenuItem>
        )}
        <MenuItem onClick={update}>
          <AddAPhoto />
          Add Picture...
        </MenuItem>
        <MenuItem onClick={generateImage}>
          <AddPhotoAlternate />
          Generate Picture...
        </MenuItem>
      </Menu>
    </>
  );
};

ModelPictureMenu.defaultProps = {};
export default ModelPictureMenu;
