import { Add, Person } from "@material-ui/icons";
import React, { useState } from "react";
import { useStar } from "../../../hooks/useStar";
import { getData } from "../../../services/VideoData";
import { same } from "../../../util/Strings";
import PopoverSelect from "../PopoverSelect/PopoverSelect";
import "./ModelPopoverSelect.css";

const find = async (param) => {
  const payload = {
    page: 1,
    sort: "ID",
    desc: 1,
    simple: 1,
    mask: `title/${param}`,
  };
  const answer = await getData(payload, "video");
  console.log({ answer });
  return answer;
};

export function useModelSelect() {
  const [rows, setRows] = useState([]);
  return { rows, setRows };
}

const ModelPopoverSelect = ({ onModelSelect, text }) => {
  const ui = useModelSelect();

  const modelState = useStar();

  const paramNode = (param) => {
    return new Promise((callback) => {
      find(param).then((data) => {
        console.log(data);
        callback([
          {
            value: `Add "${param}" as new model`,
            secondary: `${data?.rows?.length} movies found`,
            param,
            avatar: { icon: <Add /> },
          },
        ]);
      });
    });
  };

  const promise = (param) => {
    return new Promise((callback) => {
      modelState.getStar(param).then((res) => {
        const responseData = res.data;
        console.log({ responseData });
        ui.setRows(responseData);
        paramNode(param).then((add) => {
          callback(
            add.concat(
              responseData?.map((row) => ({
                value: row.name,
                id: row.ID,
                active: same(row.name, param),
                avatar: {
                  src: row.image,
                  alt: row.name,
                },
              }))
            )
          );
        });
      });
    });
  };

  const select = (option) => {
    const model = ui.rows?.filter((row) => row.ID === option.id)[0];
    console.log({ model });
    !!model && onModelSelect && onModelSelect(model);
  };

  const createStar = (name) => {
    modelState.createStar(name).then((star) => {
      onModelSelect && onModelSelect(star);
    });
  };

  const args = {
    title: text,
    icon: <Person />,
    promise,
    onSelect: (a) => {
      !!a.param && createStar(a.param); //alert(`add new model ${a.param}`);
    },
    // onSelect: (a) => {
    //   if (a.param) return alert(`Add ${a.param}`);
    //   alert(`Select ${a.value}`);
    // },
    onSave: select,
  };

  return (
    <div className="ModelPopoverSelect">
      {" "}
      <PopoverSelect {...args} />[{modelState.state.status}][{modelState.count}{" "}
      records.]
    </div>
  );
};

ModelPopoverSelect.defaultProps = {};
export default ModelPopoverSelect;
