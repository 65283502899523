import React from "react";
import { indexName, TABLE_DEF, versionNo } from "../services/DataDef";
import { LocalDb, populateDb } from "../services/IndexedDb";
import { createModel } from "../services/VideoData";
import { PromiseChain } from "../util/PromiseChain";
import { contains, createNameArray } from "../util/Strings";

export function useStar() {
  const [count, setCount] = React.useState(-1);
  const [state, setState] = React.useState({ status: "unmounted" });
  const init = React.useCallback(() => {
    LocalDb.init([TABLE_DEF], indexName, versionNo);
  }, []);

  const load = React.useCallback((num) => {
    setCount(num);
    num === 0 &&
      (() => {
        setState((s) => ({ ...s, status: "loading" }));
        populateDb(TABLE_DEF.name).then((msg) => {
          setState((s) => ({ ...s, status: msg }));
        });
      })();
    setState((s) => ({ ...s, status: "Ready." }));
  }, []);

  React.useEffect(() => {
    init();
    LocalDb.tally(TABLE_DEF.name).then(load);
  }, [init, load]);

  const createStar = (name) =>
    new Promise((callback) => {
      createModel(name).then((star) => {
        LocalDb.insert(TABLE_DEF.name, [star]).then(() => {
          callback(star);
        });
      });
    });

  const getStar = (name) =>
    new Promise((callback) => {
      LocalDb.select(TABLE_DEF.name, (row) => contains(row.name, name)).then(
        (data) => {
          callback({
            data,
            count: data?.length,
          });
        }
      );
    });

  const getStars = (value) =>
    new Promise((callback) => {
      const names =
        value.indexOf(",") > 0
          ? value.split(",")
          : createNameArray(value.split(" "));

      PromiseChain(names.map(getStar)).then((stars) => {
        console.log({ stars });
        const folks = [];
        stars?.map((star) => {
          return folks.push(...star.data);
        });
        callback(folks);
      });
    });

  return { count, state, getStar, getStars, createStar };
}
