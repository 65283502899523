const TABLE_DEF = {
  name: "starTable",
  key: "ID",
  fields: ["name", "image", "ID"],
};
const LIST_DEF = {
  name: "listTable",
  key: "name",
  fields: ["name", "items"],
};

const indexName = "localStarDb2_v9";
const versionNo = 1;

export { TABLE_DEF, LIST_DEF, indexName, versionNo };
