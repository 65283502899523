import {
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Close, Delete, PlaylistAdd } from "@material-ui/icons";
import React from "react";
import { collate } from "../../../util/Strings";
import ImageLoader from "../../Common/ImageLoader/ImageLoader";
import PaginationBar from "../../Common/PaginationBar/PaginationBar";
import { PreviewThumb } from "../../Common/Thumbnail/Thumbnail";
import ThumbnailGrid from "../../Common/Thumbnail/ThumbnailGrid/ThumbnailGrid";
import ToolTipButton from "../../Common/ToolTipButton/ToolTipButton";
import "./PlaylistModal.css";
const PAGE_SIZE = 16;
const PlaylistModal = ({
  open,
  send,
  quit,
  tracks = [],
  lists,
  createList,
  deleteTrack,
  deleteTracks,
  addTracksToList,
  tally,
}) => {
  const [selectedList, setSelectedList] = React.useState(null);
  const [selectedTracks, setSelectedTracks] = React.useState([]);
  const [state, setState] = React.useState({
    menu: false,
  });
  const [page, setPage] = React.useState(1);
  const track = tracks[0];
  const suffix = tracks?.length > 1 ? ` and ${tracks?.length - 1} more` : "";

  const selectedItems = selectedList?.items;
  const paginatorConfig = {
    startPage: (page - 1) * PAGE_SIZE,
    pageSize: PAGE_SIZE,
    length: selectedItems?.length,
    click: (v) => {
      setPage((p) => p + v);
    },
  };

  const close = () => {
    quit(false);
  };

  const create = () => {
    const name = prompt("Enter a name");
    !!name && !!createList && createList(name).then(() => send(name));
  };

  const exec = (f) => {
    !!track && send(f.name);
    !track && setSelectedList(f);
    setPage(1);
  };

  const ok = !!selectedList && !track;
  const listTitle = !ok ? "Playlists" : `Playlist: ${selectedList.name}`;

  const dialogTitle = !track
    ? listTitle
    : ` Add "${track?.title}" ${suffix} to playlist`;

  const collated = collate(selectedItems, PAGE_SIZE, page);

  const titleClick = (video) => {
    setSelectedTracks((t) =>
      !!t.filter((f) => f.ID === video.ID).length
        ? t.filter((f) => f.ID !== video.ID)
        : t.concat(video)
    );
  };

  const remove = (v) => {
    if (
      !window.confirm(
        `Remove ${selectedTracks.length} videos from ${selectedList.name}?`
      )
    )
      return;
    deleteTracks(selectedList.name, selectedTracks).then((u) => {
      setSelectedTracks([]);
      setSelectedList(u);
    });
    //  deleteTrack(selectedList.name, v).then(() => setSelectedList(null));
  };

  const buttons = [
    {
      icon: <Delete />,
      count: selectedTracks.length,
      click: () => remove(),
    },
    {
      icon: <PlaylistAdd />,
      count: selectedTracks.length,
      click: () => setState((s) => ({ ...s, menu: !s.menu })),
    },
    {
      icon: <Close />,
      click: () => {
        setState((s) => ({ ...s, menu: false }));
        setSelectedTracks([]);
      },
    },
  ];

  return (
    <div className="PlaylistModal">
      <Dialog
        open={open}
        classes={{ paper: ["modal-paper", ok ? "small" : ""].join(" ") }}
        onClose={() => close()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{ fontSize: "0.9rem" }}
          classes={{ root: "modal-title" }}
          id="alert-dialog-title"
        >
          {!!track && (
            <ImageLoader
              type="avatar"
              css="loader-avatar"
              image={track.image}
              alt={dialogTitle}
            />
          )}
          <div style={{ marginLeft: 12 }}>{dialogTitle}</div>
        </DialogTitle>
        <div style={{ margin: "0 12px" }}>
          <Collapse in={!!selectedTracks.length}>
            <div style={{ display: "flex" }}>
              {buttons?.map((button, i) => (
                <ToolTipButton small key={i} {...button} />
              ))}
            </div>
          </Collapse>
          <Collapse in={!!selectedTracks.length && !!state.menu}>
            <ListGrid
              lists={lists}
              tracks={selectedTracks}
              track={selectedTracks[0]}
              onClick={(n) =>
                addTracksToList(n.name, selectedTracks).then(() => {
                  setSelectedTracks([]);
                  setState((s) => ({ ...s, menu: false }));
                  setSelectedList(null);
                })
              }
            />
          </Collapse>
        </div>
        <Collapse in={!state.menu}>
          <DialogContent>
            {!!selectedItems?.length && <PaginationBar {...paginatorConfig} />}

            {!!selectedList && !track && (
              <ThumbnailGrid
                titleClick={titleClick}
                small
                videos={collated?.truncated?.map((f) => ({
                  ...f,
                  count: tally(f),
                  selected: !!selectedTracks.filter((b) => b.ID === f.ID)
                    .length,
                }))}
              />
            )}

            {!selectedList && (
              <ListGrid
                lists={lists}
                track={track}
                onClick={exec}
                tracks={tracks}
              />
            )}

            {!!track && (
              <li>
                <Button onClick={create}>Create new List</Button>
              </li>
            )}
            {!!selectedList && (
              <li>
                <Button onClick={() => setSelectedList(null)}>
                  Close List
                </Button>
              </li>
            )}
          </DialogContent>
        </Collapse>{" "}
      </Dialog>
    </div>
  );
};

const ListGrid = ({ lists, track, tracks, onClick }) => (
  <div className={["ThumbnailGrid", "modal-data-grid"].join(" ")}>
    {lists?.map((list) => (
      <div onClick={() => onClick(list)} className="grid-item">
        <ListThumb list={list} track={track} tracks={tracks} />
      </div>
    ))}
  </div>
);

function ListThumb({ list, track, tracks }) {
  const photo = list.items?.filter((d) => !!d.image)[0];

  const active = !!list.items?.filter((f) => tracks.some((v) => f.ID === v.ID))
    .length;
  const video = {
    Text: list.name + " [" + list.items?.length + " items]",
    Photo: !photo ? "" : photo.image,
    exact: active,
  };
  // return JSON.stringify(video);
  return <PreviewThumb {...video} />;
}

const PlaylistModalConfig = (make) => (tracks) =>
  new Promise((callback) => {
    make({
      tracks,
      open: true,
      send: (what) => {
        callback(what);
      },
      quit: (what) => {
        callback(what);
        make({ open: false });
      },
    });
  });

export { PlaylistModalConfig };

PlaylistModal.defaultProps = {};
export default PlaylistModal;
