import React, { useState } from "react";
import { DEFAULT_MODEL_IMAGE } from "../../../../Constants";
import ImageLoader from "../../ImageLoader/ImageLoader";
import ModelPictureMenu from "../../Thumbnail/ModelPictureMenu/ModelPictureMenu";
import "./ModelThumbnail.css";

const ModelThumbnail = ({ model, click, field, large, refresh }) => {
  const [error, setError] = useState(!model.image);
  return (
    <div className={["ModelThumbnail", large ? "large" : ""].join(" ")}>
      <div onClick={() => click && click(model)}>
        <ImageLoader
          setError={() => setError(true)}
          alt={model.name}
          count={field === "likedCount" ? model.likedCount : model.videoCount}
          image={model.image || DEFAULT_MODEL_IMAGE}
        />
      </div>
      <div className="app-link blue">
        {!!error ? (
          <ModelPictureMenu
            refresh={refresh}
            name={model.name}
            modelFk={model.ID}
          />
        ) : (
          <>{model.name}</>
        )}
      </div>
    </div>
  );
};

ModelThumbnail.defaultProps = {};
export default ModelThumbnail;
