import {
  CheckBox,
  Delete,
  ErrorOutline,
  FileCopy,
  FileCopyOutlined,
  Launch,
  PlaylistAddCheck,
  Sync,
} from "@material-ui/icons";
import React, { useState } from "react";
import {
  addURL,
  addURLWithModel,
  assignModel,
  deleteVideo,
} from "../../../services/VideoData";
import { WindowManagerService } from "../../../services/WindowManager";
import { PromiseChain } from "../../../util/PromiseChain";
import { keyFromText } from "../../../util/Strings";
import FlexModal, { FlexModalConfig } from "../../FlexModal/FlexModal";
// import ModelDataInput from "../Model/ModelDataInput/ModelDataInput";
import ModelPopoverSelect from "../ModelPopoverSelect/ModelPopoverSelect";
import ToolTipButton from "../ToolTipButton/ToolTipButton";
import "./VideoEditRow.css";

const VideoEditButtons = (
  count,
  refind,
  remove,
  update,
  deselect,
  add2List,
  starClip,
  setStarClip
) => [
  {
    icon: <Sync />,
    count,
    click: () => update && update(),
    when: () => !starClip,
  },
  {
    icon: <Launch />,
    count,
    click: () => refind && refind(),
    when: () => !starClip,
  },
  {
    icon: <Delete />,
    count,
    click: () => remove && remove(),
    when: () => !starClip,
  },
  {
    icon: <CheckBox />,
    click: () => deselect && deselect(),
    when: () => !starClip,
  },
  {
    icon: <ErrorOutline />,
    click: () => deselect && deselect(true),
    when: () => !starClip,
  },
  {
    icon: <PlaylistAddCheck />,
    click: () => add2List && add2List(),
    count,
    when: () => !starClip,
  },
  {
    icon: !!starClip ? <FileCopy /> : <FileCopyOutlined />,
    click: () => setStarClip && setStarClip(!starClip),
    count,
  },
];

const VideoEditRow = ({
  deselect,
  selectedItemList,
  complete,
  editingID,
  addToList,
  starClip,
  setStarClip,
  unattributed,
}) => {
  const [dialogProps, setDialogProps] = useState({});
  const selectCount = selectedItemList?.length;
  const ask = FlexModalConfig(setDialogProps);
  let selectedModelNames = null;
  const refind = () => {
    const keys = selectedItemList
      .map((f) => keyFromText(f.title))
      .filter((f) => !!f.length);
    const url = WindowManagerService.javdoeSearchLink(keys);
    remove();
    window.open(url);
  };

  const remove = () => {
    deleteKeys(selectedItemList.map((f) => f.ID));
  };

  const deleteKeys = (keys) => {
    ask(`Delete ${keys.length} items permanently?`).then((yes) => {
      if (yes) {
        PromiseChain(keys.map((key) => deleteVideo(key))).then(
          () => complete && complete()
        );
      }
    });
  };
  const update = () => {
    PromiseChain(
      selectedItemList.map((g) => {
        return editingID ? addURLWithModel(g.URL, editingID) : addURL(g.URL);
      })
    ).then(() => complete && complete());
  };
  const addModelToSelected = (star) => {
    PromiseChain(
      selectedItemList.map(({ ID }) => assignModel(star.ID, ID))
    ).then(() => complete && complete());
  };

  if (!!starClip) {
    selectedModelNames = starClip.models?.map((m) => m.name).join(" and ");
  }
  if (!selectCount) {
    if (!!starClip) {
      return (
        <em>
          Select some items to add <b>{selectedModelNames}</b> to:
        </em>
      );
    }

    return (
      <div className="flexed right">
        <em>select some items to continue...</em>
        <ToolTipButton
          small
          icon={<CheckBox />}
          title="select all"
          click={() => deselect()}
        />
        <ToolTipButton
          small
          icon={<ErrorOutline />}
          title="select all"
          click={() => deselect(true)}
        />
      </div>
    );
  }

  const buttons = VideoEditButtons(
    selectCount,
    refind,
    remove,
    update,
    deselect,
    () => addToList(selectedItemList),
    starClip,
    setStarClip
  );

  return (
    <div className="VideoEditRow flexed right">
      {buttons
        ?.filter((b) => !b.when || b.when())
        .map((button, i) => (
          <ToolTipButton small key={i} {...button} />
        ))}

      {!!selectedModelNames && <em>adding "{selectedModelNames}"</em>}

      {!selectedModelNames && (
        <ModelPopoverSelect
          text={`add model to ${selectCount} videos`}
          onModelSelect={(m) => addModelToSelected(m)}
        />
      )}

      <FlexModal {...dialogProps} />
    </div>
  );
};

VideoEditRow.defaultProps = {};
export default VideoEditRow;
